<template>
  <div class="table" style="width: 100%">
    <h1 style="margin-bottom: 50px">抖音直播评论查询✨</h1>
    <el-form :inline="true">
        <el-form-item label="直播时间">
          <el-date-picker
            v-model="form.datetime"
            type="datetimerange"
            @change="loadRoomIds"
            :default-time="['00:00:00', '23:59:59']"
            format = "yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            value-format="yyyy/MM/dd HH:mm:ss"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="直播场次">
          <el-select style="width: 250px;" v-model="form.liveRoomId" clearable  multiple collapse-tags placeholder="请选择">
            <el-option
              v-for="item in liveRoomIds"
                :key="item.liveRoomId"
                :label="item.startTime"
                :value="item.liveRoomId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="昵称">
          <el-input v-model="form.nickName"></el-input>
        </el-form-item>
        
    </el-form>
    <el-form :inline="true">
      <el-form-item label="评论内容">
          <el-input v-model="form.keyWord" style="width:400px;"></el-input>
      </el-form-item>
      <el-form-item label="内部人员">
          <el-select style="width: 250px;" v-model="form.nickNames"  multiple clearable  collapse-tags placeholder="请选择">
          <el-option label='全选' value='全选' @click.native='selectAll'></el-option>
            <el-option
              v-for="item in nickNames"
                :key="item.uuid"
                :label="item.nickName"
                :value="item.nickName">
            </el-option>
          </el-select>
        </el-form-item>
      <el-form-item>
        <el-form-item><el-button type="primary" @click="show">增加内部人员</el-button></el-form-item>
        <el-form-item><el-button type="primary" @click="search">查询</el-button></el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="exportData">导出excel</el-button>
        </el-form-item>
      </el-form-item>
    </el-form>
    <el-divider v-if="flag == 0">
      <i class="el-icon-check" style="color: #aaaaaa"></i>
    </el-divider>
    <el-divider v-else>
      <i class="el-icon-close" style="color: #aaaaaa"></i>
    </el-divider>
    <div name="table">
      <div class="block">
         <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page.curr"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total">
        </el-pagination>
      </div>
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="nick_name" label="昵称"/>
        <el-table-column prop="content" label="内容"/>
        <el-table-column prop="createtime" label="创建时间"/>
        <!-- <el-table-column prop="important_msg" label="是否为重要信息"/> -->
        <el-table-column prop="startTime" label="直播开始时间"/>
      </el-table>
      <el-dialog title="新增内部人员昵称" :visible.sync="dialogFormVisible">
      <el-form  :inline="true">
        <el-form-item label="昵称">
          <el-input v-model="nickNameForm.name" ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false;">取 消</el-button>
        <el-button type="primary" @click="addNickName">确 定</el-button>
      </div>
    </el-dialog>
    </div>
  </div>
</template>

<script>
// import JsonExcel from 'vue-json-excel'
import { Message } from "element-ui";
import dayjs from 'dayjs'
// 或者 CommonJS
// var dayjs = require('dayjs');
dayjs().format();
export default {
  name: "HelloWorld",
  data() {
    return {
      form:{
        datetime : this.defaultDate(),
        keyWord:"",
        liveRoomId:[],
        nickName:""
      },
      json_fields: {
        昵称: "nick_name",
        内容: "content",
        创建时间: "createtime",
        直播开始时间: "startTime",
      },
      liveRoomIds:[],
      flag: "",
      pageSizes:[100,200,500],
      id: "",
      nickNameForm:{
        name:""
      },
      nickNames:{

      },
      month1: null,
      tableData: [
        
      ],
      roomInfos:{},
      dialogFormVisible: false,
      page:{
        total:0,
        pageSize: 100,
        curr:1
      },
      url:{
        "liveRoomIds":"./live/realttime",
        // "liveComment":"http://127.0.0.1:8091/liveComment",
        "liveComment":"/liveComment",
        "liveCommentExcel":"./liveComment/excel",
        "nickName":"./filter/nickName"
      }
    };
  },
  computed: {
  },
  created(){
    this.loadRoomIds();
    this.loadData();
    this.loadNickName();
  },
  mounted() {
    console.log(this.form.datetime);
  },
  methods: {
    show(){
      this.dialogFormVisible = true;
      this.nickNameForm.name = '';
    },
    exportData(){
      let roomIds = "";
      if(Array.isArray(this.form.loadRoomId)){
        roomIds = this.form.liveRoomId.join(",");
      }else{
        roomIds = this.form.liveRoomId;
      }
      if(!roomIds || roomIds == ""){
        return;
      }
      let url = this.url.liveCommentExcel+"?roomIds="+roomIds+"&nickNames="+this.form.nickNames+"&nickName="+this.form.nickName+"&keyWord="+this.form.keyWord+"&curr="+this.page.curr+"&size="+this.page.pageSize;
      window.open(url)
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.page.pageSize = val;
      this.loadData();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page.curr = val;
      this.loadData();
    },
    loadNickName(){
        this.$axios.get(this.url.nickName).then((res)=>{
          this.nickNames = res.data.data;
        })
    },
    addNickName(){
      if(this.nickNameForm.name == ""){
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("nickName",this.nickNameForm.name)
      this.$axios.post(this.url.nickName,bodyFormData, {headers:{ "Content-Type": "multipart/form-data" }})
      .then((res)=>{
        if(res.data.code == 0){
            this.loadNickName();
            this.dialogFormVisible = false;
        } else {
            Message.warning({
              message: res.data.msg,
            });
        }
      })
    },
    defaultDate(){
        var date = new Date();date.setHours("00");date.setMinutes("00");date.setSeconds("00");
        var date1 = new Date();date1.setHours("23");date1.setMinutes("59");date1.setSeconds("59");
        return [dayjs(date).format("YYYY/MM/DD HH:mm:ss"), dayjs(date1).format("YYYY/MM/DD HH:mm:ss")];
    },
    loadRoomIds(){
      if(this.form.datetime == null){
        this.liveRoomIds = [];
        this.form.liveRoomId = new Array();
        return;
      }
      this.roomInfos = {};
      this.liveRoomIds = new Array();
      this.form.liveRoomId = new Array();
      let url = this.url.liveRoomIds+"?beginTime="+this.form.datetime[0]+"&endTime="+this.form.datetime[1];
      this.$axios.get(url).then((res) => {
        this.liveRoomIds = res.data.data;
        res.data.data.forEach(item=>{
          this.form.liveRoomId.push(item.liveRoomId)
          this.roomInfos[item.liveRoomId] = item.startTime;
        })
      })
      .catch((error) => console.log(error));
    },
    selectAll(){
      if (this.form.nickNames.length < this.nickNames.length) {
        this.form.nickNames = []
        this.nickNames.map((item) => {
          this.form.nickNames.push(item.nickName)
        })
        this.form.nickNames.unshift('全选')
      } else {
        this.form.nickNames = []
      }
    },
    search(){
      this.page.curr = 1;
      this.loadData();
    },
    loadData() {
      let roomIds = "";
      if(Array.isArray(this.form.loadRoomId)){
        roomIds = this.form.liveRoomId.join(",");
      }else{
        roomIds = this.form.liveRoomId;
      }
      if(!roomIds || roomIds == ""){
        return;
      }
      let url = this.url.liveComment+"?roomIds="+roomIds+"&nickNames="+this.form.nickNames+"&nickName="+this.form.nickName+"&keyWord="+this.form.keyWord+"&curr="+this.page.curr+"&size="+this.page.pageSize;
      this.$axios.get(url).then((res) => {
          if (res.data.code == "0" && res.data.data == "") {
            Message.warning({
              message: "无数据",
            });
          } else if (res.data.code == "0") {
            Message.success({
              message: "查询成功",
            });
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
            this.tableData.forEach(item=>{
              item["createtime"] = dayjs(item.createtime).format("YYYY-MM-DD HH:mm:ss")
              item["startTime"] = this.roomInfos[item.live_room_id];
            })
          }
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style>
</style>
