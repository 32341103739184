<template>
  <div>
    <el-container>
      <el-header class="header">
        <!-- <el-image src="https://shoplineimg.com/5fd8642c6c0f2000220dd7cc/60615fd3c71a7a0029d8efe0/x280.webp?source_format=png" style="width:200px"></el-image> -->
      </el-header>
      <el-main>
        <el-backtop></el-backtop>
        <el-row type="class">
          <el-col :span="20" :offset="2" class="grid-content"
            ><hello-world
          /></el-col>
        </el-row>
      </el-main>
      <el-footer>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";
export default {
  name: "App",
  components: {
    HelloWorld,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
  margin-top: 30px;
}
body {
  /* add */
  margin: 0;
  padding: 0;
}
.header{
  /* background: blue; */
}
</style>
